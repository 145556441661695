import merge from "lodash/merge";
import germanMessages from "ra-language-german";
import englishMessages from "ra-language-english";
import resources from "../config/resources";

const customRaMessages = {
  action: {
    add_filter: "Filter hinzufügen",
    add: "Neu",
    back: "Zurück",
    bulk_actions: "Ein Element ausgewählt |||| %{smart_count} Elemente ausgewählt",
    cancel: "Abbrechen",
    clear_input_value: "Eingabe löschen",
    clone: "Klonen",
    confirm: "Bestätigen",
    create: "Erstellen",
    delete: "Löschen",
    edit: "Bearbeiten",
    export: "Exportieren",
    list: "Liste",
    refresh: "Aktualisieren",
    remove_filter: "Filter entfernen",
    remove: "Entfernen",
    save: "Speichern",
    add_brocken: "Ausschuss",
    search: "Suchen",
    show: "Anzeigen",
    sort: "Sortieren",
    undo: "Zurücksetzen",
    unselect: "Auswahl aufheben",
    expand: "Erweitern",
    close: "Schließen",
    open_menu: "Menü öffnen",
    close_menu: "Menü schließen",
    download: "Herunterladen",
    share: "Teilen",
    confirm_empty: "Leere Eingabe bestätigen",
    sendToPrintJob: `An Bauraum %{id} senden`,
  },
  boolean: {
    true: "Ja",
    false: "Nein",
    null: " ",
  },
  page: {
    create: "Rapidobject Auftragssteuerung: %{name} erstellen",
    dashboard: "Rapidobject Auftragssteuerung",
    edit: "Rapidobject Auftragssteuerung: %{name} #%{id}",
    error: "Etwas ist schief gelaufen",
    list: "Rapidobject Auftragssteuerung: %{name}",
    loading: "Lädt",
    not_found: "Nicht gefunden",
    show: "Rapidobject Auftragssteuerung: %{name} #%{id}",
    empty: "Es existieren keine %{name}",
    delete: "Rapidobject Auftragssteuerung: %{name} #%{id} löschen",
    invite: "Erstellen Sie doch den ersten Eintrag?",
  },
  input: {
    file: {
      upload_several: "Zum Hochladen Dateien hineinziehen oder hier klicken, um Dateien auszuwählen.",
      upload_single: "Zum Hochladen Datei hineinziehen oder hier klicken, um eine Datei auszuwählen.",
    },
    image: {
      upload_several: "Zum Hochladen Bilder hineinziehen oder hier klicken, um Bilder auszuwählen.",
      upload_single: "Zum Hochladen Bild hineinziehen oder hier klicken, um eine Bild auszuwählen.",
    },
    references: {
      all_missing: "Die zugehörigen Referenzen konnten nicht gefunden werden.",
      many_missing: "Mindestens eine der zugehörigen Referenzen scheint nicht mehr verfügbar zu sein.",
      single_missing: "Eine zugehörige Referenz scheint nicht mehr verfügbar zu sein.",
    },
    password: {
      toggle_visible: "Passwort verstecken",
      toggle_hidden: "Passwort anzeigen",
    },
  },
  message: {
    about: "Über",
    are_you_sure: "Bist du sicher?",
    bulk_delete_content:
      'Möchten Sie "%{name}" wirklich löschen? |||| Möchten Sie diese %{smart_count} Elemente wirklich löschen?',
    bulk_delete_title: "Lösche %{name} |||| Lösche %{smart_count} %{name} Elemente",
    delete_content: "Möchten Sie diesen Inhalt wirklich löschen?",
    delete_title: "Lösche %{name} #%{id}",
    details: "Details",
    error: "Ein Fehler ist aufgetreten und ihre Anfrage konnte nicht abgeschlossen werden.",
    invalid_form: "Das Formular ist ungültig. Bitte überprüfen Sie ihre Eingaben.",
    loading: "Die Seite wird geladen.",
    no: "Nein",
    not_found: "Die Seite konnte nicht gefunden werden.",
    yes: "Ja",
    unsaved_changes: "Sie haben nicht gespeicherte Inhalte. Sicher dass Sie fortfahren möchten?",
    notYetSupported: "Noch nicht unterstützt",
    add_brocken_part: "Ausschuss verbuchen",
  },
  navigation: {
    no_results: "Keine Resultate gefunden",
    no_more_results: "Die Seite %{page} enthält keine Inhalte.",
    page_out_of_boundaries: "Die Seite %{page} liegt ausserhalb des gültigen Bereichs",
    page_out_from_end: "Letzte Seite",
    page_out_from_begin: "Erste Seite",
    page_range_info: "%{offsetBegin}-%{offsetEnd} von %{total}",
    page_rows_per_page: "Pro Seite:",
    next: "Weiter",
    prev: "Zurück",
  },
  auth: {
    auth_check_error: "Bitte melden Sie sich an, um fortzufahren",
    user_menu: "Profil",
    username: "Nutzername",
    password: "Passwort",
    sign_in: "Anmelden",
    sign_in_error: "Fehler bei der Anmeldung",
    incorrectLogin: "Falscher Benutzername oder falsches Passwort",
    logout: "Abmelden",
  },
  notification: {
    updated: "Element(e) erfolgreich aktualisiert",
    created: "Element wurde erstellt",
    deleted: "Element wurde gelöscht |||| %{smart_count} Elemente wurden gelöscht",
    bad_item: "Fehlerhafte(s) Element(e)",
    item_doesnt_exist: "Das Element existiert nicht",
    http_error: "Fehler beim Kommunizieren mit dem Server",
    data_provider_error: "Interner Fehler",
    i18n_error: "Fehler in der Übersetzung",
    canceled: "Aktion abgebrochen",
    logged_out: "Ihre Session wurde beendet, bitte melden Sie sich erneut an",
  },
  validation: {
    required: "Benötigt",
    minLength: "Muss mindestens %{min} Zeichen lang sein",
    maxLength: "Darf maximal %{max} Zeichen lang sein",
    minValue: "Muss mindestens %{min} sein",
    maxValue: "Muss %{max} oder weniger sein",
    number: "Muss eine Nummer sein",
    email: "Muss eine gültige E-Mail sein",
    oneOf: "Es muss einer sein von: %{options}",
    regex: "Es muss folgendem, regulären Ausdruck entsprechen: %{pattern}",
    regexFormat: "Ungültiger RegEx (Regulärer Ausdruck)",
  },
};

export default {
  format: {
    date: {
      isNull: "Nicht gesetzt",
      dateFormat: "DD.MM.YYYY",
      dateTimeFormat: "DD.MM.YYYY HH:mm",
    },
    nMore: "%{smart_count} weiteres |||| %{smart_count} weitere",
  },
  resources: {
    [resources.ORDERS]: {
      name: "Bestellung |||| Bestellungen",
      fields: {
        id: "ID",
        rapidshopId: "Bestellnr.",
        active: "Aktiv",
        customer: "Kunde",
        paymentAddress: "Rechnungsadresse",
        dateCreated: "Upload-Datum",
        dateUpdated: "Zuletzt geändert",
        dateOrdered: "Bestelldatum",
        models: "Modelle",
        materials: "Materialien",
        shipments: "Sendungen",
        contactorRapidshopId: "Interne Bestell-Nr.",
        internalContactPerson: "Interner Ansprechpartner",
      },
    },
    [resources.SHIPMENTS]: {
      name: "Bestellung |||| Bestellungen",
      fields: {
        id: "ID",
        rapidshopId: "Lieferschein-Nr.",
        orderRapidshopId: "Bestellnr.",
        paymentComplete: "Bezahlt",
        models: "Modelle",
        customerAddressDelivery: "Versandadresse",
        order: "Bestellung",
        numberPayment: "Rechungsnummer",
        datePayment: "Zahlungseingang",
        originalDateShipping: "Urspr. Liefertermin",
        dateDue: "Zahlungsziel",
        dateInvoice: "Rechnungsdatum",
        dateShipping: "Versanddatum",
        dateCancellation: "Storno Datum",
        canceled: "Storniert",
        invoicePdfUrl: "Rechnung",
        orderConfirmationPdfUrl: "Auftragsbestätigung",
        deliveryNotePdfUrl: "Lieferschein",
        creditNotePdfUrl: "Gutschein",
        customer: "Kunde",
        materials: "Materialien",
        dateOrdered: "Bestelldatum",
        modelStatus: "Bestellstatus",
        payment: "Zahlung",
        express: "Express",
        noExpress: "Kein Express",
        paymentWarningTitle: "Achtung",
        paymentWarningMessage:
          "Die von Ihnen ausgewählte Bestellung ist noch nicht bezahlt. Sind Sie sicher, dass Sie den Auftrag auslösen möchten?",
        assignee: "Mitarbeiter",
        comments: "Kommentare",
      },
    },
    [resources.CUSTOMERS]: {
      name: "Kunde |||| Kunden",
      fields: {
        id: "ID",
        rapidshopId: "Rapidshop-ID",
        debitorNumber: "Kunden-Nr.",
        orgqaMaxCustomerNumber: "Orgamax-Kunden-Nr.",
        defaultInvoiceAddress: "Standart Rechnungsadresse",
        defaultDeliveryAddress: "Standart Lieferadresse",
        customerAddresses: "Adressen",
        orders: "Bestellungen",
        title: "Titel",
        prename: "Vorname",
        lastname: "Nachname",
        gender: "Geschlecht",
        companyName: "Firma",
        email: "E-Mail",
        emailInvoice: "Rechnungs E-Mail",
        phone: "Telefon",
        phoneMobile: "Mobil",
        fax: "Fax",
        dayOfBirth: "Geburtsdatum",
        active: "aktiv",
        allowNewsletter: "Newsletter",
        allowNotifications: "Benachrichtigungen",
        customerType: "Kundenart",
        note: "Kundenhinweis",
        internalOfferNotice: "Interne Notiz",
      },
    },
    [resources.PRICE_ESTIMATES]: {
      name: "Angebot |||| Angebote",
      fields: {
        id: "ID",
        model: "Modell",
        contractor: "Dienstleister",
        supportedMaterial: "Material",
        priceValue: "Gesamtpreis",
        priceValueContractor: "Fertigungskosten Gesamt",
        modelPriceValue: "Verkaufspreis",
        sumValue: "Summe",
        assign: "Zuweisen",
        productionDays: "Produktionszeit",
        timeRemainingTooltip:
          "Produziert bis %{smart_count} Tag vor geplanter Lieferung. |||| Produziert bis %{smart_count} Tage vor geplanter Lieferung.",
        timeOverdueTooltip:
          "Produziert bis %{smart_count} Tag nach geplanter Lieferung. |||| Produziert bis %{smart_count} Tage nach geplanter Lieferung.",
      },
    },
    [resources.FILE_VERSIONS]: {
      name: "Datei-Version |||| Datei-Versionen",
      fields: {
        id: "ID",
        uuid: "Versions-Nr.",
        dateCreated: "Erstelldatum",
        dateUpdated: "Änderungsdatum",
        checksum: "Prüfsumme",
        fileUuid: "Datei-Nr.",
        accessToken: "Zugangscode",
        activeVersion: "Aktiv",
        upload: "Hochladen",
        download: "Herunterladen",
        name: "Dateiname",
      },
    },
    [resources.JOBS]: {
      name: "Sub. Auftrag |||| Sub. Aufträge",
      fields: {
        id: "ID",
        uuid: "Referenz",
        models: "Modelle",
        contractor: "Dienstleister",
        dateEstimate: "Produktionsziel",
        status: "Status",
        price: "Auftragskosten",
        contractorPrice: "Berechnete Fertigungskosten:",
        priceValue: "Auftragskosten",
        action: "Aktion",
        sendOffer: "Bestellen / Auftrag absenden",
        accept: "Auftrag bestätigen",
        orderConfirmationMissing: "Auftragsbestätigung fehlt",
        showContractorView: "Zu Dienstleister-Ansicht wechseln",
        setInTransport: "Auftrag als verschickt markieren",
        setReceived: "Bestellung eingetroffen",
        minimumPrice: "Mindestbestellwert",
        partsPrice: "Produktionskosten",
        transportPrice: "Lieferkosten",
        discount: "Rabatt",
        totalPrice: "Gesamt",
        contractorIssueResolveNote: "Anmerkung an Dienstleister",
        cancel: "Storno",
        externalId: "Auftrags-Nr.",
        assignee: "Mitarbeiter",
        productionPortfolio: "Mappe",
        orderConfirmationCheckOutstanding: "AB Prüfung ausstehend",
        confirmOC: "AB geprüft",
        sizeCategory: "Kategorie",
        threeDData: "3D Daten",
        received: "Angelieferte Sub. Aufträge",
        printJobExists: "Bauraum zugewiesen",
        assignedModels: "Anz. Teile in Zugewiesen",
      },
    },
    [resources.MODELS]: {
      name: "Modell |||| Modelle",
      fields: {
        id: "ID",
        rapidshopId: "Modell-Nr.",
        order: "Bestellung",
        shipment: "Lieferung",
        dateOrdered: "Bestelldatum",
        amount: "Anzahl",
        volume: "Volumen",
        height: "Höhe",
        width: "Breite",
        dimensions: "Maße",
        length: "Tiefe",
        surface: "Oberfläche",
        methodName: "Methode",
        modelFileUrl: "Download",
        modelPreviewUrl: "Vorschau",
        netPrice: "Preis",
        shopStatus: "Kunden Status",
        rohStatus: "Status",
        job: "Produktionsauftrag",
        uuid: "Referenz-Nr.",
        size: "Abmaße",
        assign: "Modell an Sub. zuweisen",
        unassign: "Sub. Zuweisung aufheben",
        material: "Material",
        sizeHWL: "Abmaße (Höhe x Breite x Tiefe)",
        markNeedsUpdate: "Nicht produzierbar",
        modelErrorLbl: "Die 3D-Daten wurden als fehlerhaft markiert! ",
        markAsFixed: "Als repariert markieren",
        editFiles: "Daten bearbeiten",
        download: "Datei Herunterladen",
        showJob: "zu Auftrag wechseln",
        addition: "Veredelung",
        notice: "Bauteilhinweis",
        singlePrice: "Einzelpreis",
        totalPrice: "Gesamtpreis",
        noteToContractor: "Notiz für Sub. Dienstleister",
        noteToContractorMultiModelWarning: "Die Notizen aller Modelle werden überschrieben!",
        noteToContractorNotSupported: "Notiz nicht unterstützt",
        noteToContractorCV: "Anmerkung", //On contractor view
        useNoticeAsNoteToContractor: "Bauteilhinweis übernehmen",
        addNoticeToNoteToContractor: "Bauteilhinweis anfügen",
        addCustomerNoteToNoteToContractor: "Kundennotiz anfügen",
        addAllNoticesToNoteToContractor: "Alle Bauteilhinweise anfügen",
        saveNoteToContractor: "Notiz speichern",
        appendNoteToContractor: "Notiz erweitern",
        prepaymentNotComplete: "Vorkasseauftrag ohne Zahlungseingang",
        paymentComplete: "Vollständig Bezahlt",
        paymentOk: "Zahlung nach Produktion",
        missingPriceEstimates:
          "Für dieses Modell existieren keine Angebote oder dem Material ist kein Dienstleister zugewiesen.",
        multipleNotesWarning: "Achtung: Es existieren verschiedene Kundennotizen für die ausgewählten Modelle.",
        noteToContractorFailed: "Es gab einen Fehler beim übermitteln der Notiz, bitte versuchen Sie es erneut.",
        qaApproved: "WAP Abgeschlossen",
        qaFailed: "WAP Fehler",
        additionDone: "Nachbearbeitung fertig",
        miniSeriesActive: "Kleinserie aktiv",
        miniSeriesNotice: "Notiz Kleinserie",
        sameChecksumOrders: "Andere Bestellungen",
        warehouseCompartment: "Lagerfach",
        downloadFileName: `Modelldaten%{id}.zip`,
      },
    },
    [resources.MATERIALS]: {
      name: "Material |||| Materialien",
      fields: {
        id: "ID",
        assign: "Material an Sub. zuweisen",
        models: "Modelle",
        name: "Name",
        method: "Verfahren",
        supportedMaterials: "Anzahl Dienstleister",
        supportsMaterialise: "Materialise Bereit",
        autoAssignFormula: "Formel Autom. Zuweisung",
      },
    },
    [resources.CONTRACTORS]: {
      name: "Dienstleister",
      fields: {
        id: "ID",
        contractorName: "Name",
        contractorType: "Typ",
        materialPartition: "Verschiedene Materialien verschiedenen Sub. Aufträgen zuordnen",
        jobs: "Aufträge",
        emailAddress: "E-Mail",
        supportedMaterials: "Unterstützte Materialien",
        templates: "E-Mail Vorlagen",
        autoOrderTime: "Minuten bis Automatische Bestellung",
        needsQualityControl: "WAP Nötig",
        forceOrderConfirmation: "Erzwinge AB vor Bestätigung",
        checkOrderConfirmation: "AB Prüfung",
        autoAssignUser: "Auto. Zuzuweisender Mitarbeiter",
        templateLabelError: "Fehler",
      },
    },
    [resources.USERS]: {
      name: "Benutzer",
      fields: {
        id: "ID",
        username: "Benutzername",
        displayName: "Anzeigename",
        contractorId: "Dienstleister",
        contractor: "Dienstleister",
        authorities: "Rollen / Rechte",
        enabled: "Aktiv",
        locked: "Gesperrt",
        credentialsExpired: "Passwort abgelaufen",
        accountExpired: "Benutzer abgelaufen",
      },
    },
    [resources.FORMULAS]: {
      name: "Formeln",
      fields: {
        id: "ID",
        version: "Version",
        formula: "Formel",
        formulaStr: "Formel",
        type: "Typ",
        formulaParameters: "Verfügbare Parameter (+Beispielwerte)",
        formulaResult: "Ergebnis mit Beispielwerten: ",
        formulaError: "Formel ist fehlerhaft!",

        autoAssignMaterials: "Materialien für automatische Zuweisung",

        editParameters: "Parameter bearbeiten",
        saveParameters: "Parameter übernehmen",
      },
    },
    [resources.TEMPLATES]: {
      name: "Templates",
      fields: {
        id: "ID",
        name: "Name",
        type: "Typ",
        subject: "Betreff",
        body: "Inhalt",
      },
    },
    [resources.SUPPORTED_MATERIALS]: {
      name: "Unt. Materialien",
      fields: {
        id: "ID",
        contractor: "Dienstleister",
        contractors: "Dienstleister",
        material: "Material",
        materials: "Materialien",
        priceFormula: "Preis-Formel",
        productionTime: "Produktionszeit",
      },
    },
    [resources.SUBTASKS]: {
      name: "Unteraufgaben",
      fields: {
        id: "ID",
        status: "Status",
        shipment: "Bestellung",
        model: "Modell",
        title: "Titel",
        description: "Beschreibung",
        type: "Typ",
        rohStatus: "Status",
        addition: "Veredelung",
      },
    },
    [resources.MATERIALISE_MATERIAL_MAPPING]: {
      name: "Materialise",
      fields: {
        id: "ID",
        internalMaterial: "Material (intern)",
        materialiseTechnology: "Materialise Technologie",
        materialiseMaterial: "Materialise Material",
        materialiseFinishing: "Materialise Veredelung",
        materialiseColor: "Materialise Farbe",
      },
    },
    [resources.MATERIALISE_TECHNOLOGIES]: {
      name: "Materialise",
    },
    [resources.PROPERTIES]: {
      name: "Einstellungen",
    },
    [resources.MODEL_STATUS_UPDATES]: {
      name: "Audit |||| Audits",
      fields: {
        id: "ID",
        time: "Zeit",
        transaction: "Aktion",
        username: "Benutzer",
        model: "Modell-Nr.",
        job: "Sub. Auftrag Nr.",
      },
    },
    [resources.MODEL_PARTS_DEFECTS]: {
      name: "Defekt |||| Defekte",
      fields: {
        dialogTitle: "Bitte geben Sie an in welchem Status der Ausschuss entstanden ist und dessen Menge",
        count: "Menge",
        location: "Fehlerort",
        type: "Fehlerart",
        unassignedCount: "UNZUGEORDNET", //According to ROD-241 it is temporarily out
        assignedCount: "ZUGEWIESEN",
        packedCount: "VERPACKT",
        printingCount: "DRUCKEN",
        printedCount: "GEDRUCKT",
        receivedCount: "ERHALTEN",
        postProcessingCount: "NACHBEARBEITUNG",
        qaCount: "WAP",
        readyForShippingCount: "VERSANDFERTIG",
        shippedCount: "VERSANDT",
        defectCount: "DEFEKT",
      },
    },
    [resources.WAREHOUSE_LOCATIONS]: {
      name: "Lager |||| Lager",
      fields: {
        id: "ID",
        name: "Name",
        description: "Beschreibung",
        label: "Label",
        priority: "Bevorzugung",
        enabled: "Aktiv",
        forStatus: "Für Status",
        modelAmount: "# Modelle",
        availableCompartments: "Verfügbare Lagerfächer",
      },
    },
    [resources.WAREHOUSE_COMPARTMENTS]: {
      name: "Lagerfach |||| Lagerfächer",
      fields: {
        id: "ID",
        name: "Name",
        number: "Nummer",
        new: "Neu",
        __id: "ID",
        rapidshopId: "Modell-Nr",
        materialName: "Material",
        amount: "Menge",
        contractorName: "Sub. Dienstleister",
        dateOrdered: "Bestelldatum",
        methodName: "Methode",
        assignedModels: "Zugewiesene Modelle",
        noAssignedModels: "Diesem Lagerfach sind noch keine Modelle zugewiesen",
        shipment: "Bestellung",
        modelAmount: "Modelle",
        job: "Sub. Auftrag",
        assign: "Zuweisen",
      },
    },
    [resources.ADDITIONS]: {
      name: "Veredelung |||| Veredelungen",
      fields: {
        id: "ID",
        colorCode: "Farbcode",
        colorName: "Farbe",
        models: "Modelle",
        name: "Name",

        editParameters: "Parameter bearbeiten",
        saveParameters: "Parameter übernehmen",
      },
    },
    [resources.SUBTASKS]: {
      name: "Aufgabe |||| Aufgaben",
      fields: {
        title: "Titel",
        description: "Beschreibung",
        rohStatus: "Zu bearbeiten während",
        addition: "Nachbearbeitung",
      },
    },
    [resources.MATERIALISE_EXCEPTIONS]: {
      name: "Materialise Warnung |||| Materialise Warnungen",
      fields: {
        timestamp: "Zeit",
        code: "Fehler",
        message: "Hinweis",
        model: "Model",
      },
    },
    [resources.DOCUMENT_TYPES]: {
      name: "Dokument-Typen",
      fields: {
        uuid: "ID",
        name: "Name",
        labelInternal: "Bezeichnung Intern",
        labelExternal: "Bezeichnung Extern",
        fileFormatRegex: "Regex Dateiname",
        uploadableByContractor: "Dienstleister darf Hochladen",
        uploadableBySales: "Vertrieb darf Hochladen",
        updatableBySales: "Vertrieb darf Bearbeiten",
        multipleUploadsAllowed: "Mehrere Uploads erlaubt",
        overwriteable: "Überschreibbar",
        documents: "Dokumente",
        visibleToSub: "Für Sub. sichtbar",
        visibleInternally: "Intern sichtbar",
        orderConfirmation: "Ist Auftragsbestätigung des Sub.",
      },
    },
    [resources.DOCUMENTS]: {
      name: "Dokumente",
      fields: {
        downloadUrl: "Datei",
        createdAt: "Datum Upload",
        documentType: "Typ",
        job: "Sub. Auftrag",
        originalUploader: "Hochgeladen von",
        mimeType: "MIME-Type",
      },
    },
    [resources.FILTER_GRAPHS]: {
      name: "Filter",
      options: "Filteroptionen",
      set: "Filter enthalten",
      or: "Oder",
      actions: {
        save: "Filter speichern",
        select: "Filter auswählen",
        share: "Filter teilen",
        update: "Filter überschreiben",
        create: "Neuen Filter erstellen",
      },
      fields: {
        id: "ID",
        name: "Name",
        owner: "Nutzer",
        filteredEntity: "Ressource",
        ownerMayShare: "Teilbar",
        ownerMayEdit: "Bearbeitbar",
        ownerMaySee: "Sichtbar",
        type: "Typ",
        sharedWithUsers: "Geteilt mit",
      },
    },
    [resources.COMMENTS]: {
      name: "Kommentare",
      validationError: "Dieser Kommentar kann nicht angezeigt werden.",
      noComments: "Es existieren keine Kommentare.",
      shipmentTitle: "Diese Bestellung",
      jobsTitle: "Verknüpfte Sub. Aufträge",
    },
    [resources.ADDRESSES]: {
      name: "Adresse |||| Adressen",
    },
    [resources.USER_DETAILS]: {
      name: "Benutzer",
    },
    [resources.ART_JOBS]: {
      name: "Art Job |||| Art Jobs",
    },
    [resources.FILTER_PARTS]: {
      name: "Filter Feld |||| Filter Felder",
    },
    [resources.FILES]: {
      name: "Datei |||| Dateien",
    },
    [resources.ASSIGNED_USERS]: {
      name: "Zugewiesener Nutzer |||| Zugewiesene Nutzer",
    },
    [resources.NOTIFICATION_CONFIGS]: {
      name: "Meldungen Einst.",
      fields: {
        owner: "Besitzer",
        notificationType: "Thema",
        push: "Browser Benachrichtigung",
        disabled: "Deaktiviert",
        notificationStyle: "Priorität",
        template: "E-Mail Template",
      },
    },
    [resources.NOTIFICATIONS]: {
      name: "Meldungen",
      fields: {
        createdAt: "Datum",
        notificationType: "Thema",
        body: "Inhalt",
        readAt: "Gelesen",
      },
    },
    [resources.AUTO_ASSIGN_LOGS]: {
      name: "Aut. Zuweisung |||| Aut. Zuweisungen",
      fields: {
        id: "ID",
        created: "Erstellt am",
        status: "Status",
        formula: "Formel",
        formulaVersion: "Version",
        priceEstimate: "Preis",
        message: "Nachricht",
        model: "Modell",
        contractor: "Dienstleister",
        job: "Sub.Auftrag",
      },
    },
    [resources.ARCHIVED_SHIPMENTS]: {
      name: "Archiv. Bestellung |||| Archiv: Bestellungen",
      fields: {
        id: "ID",
        rapidshopId: "Lieferschein-Nr.",
        paymentComplete: "Bezahlt",
        models: "Modelle",
        customerAddressDelivery: "Versandadresse",
        order: "Bestellung",
        numberPayment: "Rechungsnummer",
        datePayment: "Zahlungseingang",
        originalDateShipping: "Ursprünglicher Liefertermin",
        dateDue: "Zahlungsziel",
        dateInvoice: "Rechnungsdatum",
        dateShipping: "Versanddatum",
        dateCancellation: "Storno Datum",
        canceled: "Storniert",
        invoicePdfUrl: "Rechnung",
        orderConfirmationPdfUrl: "Auftragsbestätigung",
        deliveryNotePdfUrl: "Lieferschein",
        creditNotePdfUrl: "Gutschein",
        customer: "Kunde",
        materials: "Materialien",
        dateOrdered: "Bestelldatum",
        modelStatus: "Bestellstatus",
        payment: "Zahlung",
        express: "Express",
        noExpress: "Kein Express",
        paymentWarningTitle: "Achtung",
        paymentWarningMessage:
          "Die von Ihnen ausgewählte Bestellung ist noch nicht bezahlt. Sind Sie sicher, dass Sie den Auftrag auslösen möchten?",
        assignee: "Mitarbeiter",
        comments: "Kommentare",
      },
    },
    [resources.ARCHIVED_JOBS]: {
      name: "Sub. Auftrag |||| Archiv: Sub. Aufträge",
      fields: {
        id: "ID",
        uuid: "Referenz",
        models: "Modelle",
        contractor: "Dienstleister",
        dateEstimate: "Produktionsziel",
        status: "Status",
        price: "Auftragskosten",
        contractorPrice: "Berechnete Fertigungskosten:",
        priceValue: "Auftragskosten",
        action: "Aktion",
        sendOffer: "Bestellen / Auftrag absenden",
        accept: "Auftrag bestätigen",
        orderConfirmationMissing: "Auftragsbestätigung fehlt",
        showContractorView: "Zu Dienstleister-Ansicht wechseln",
        setInTransport: "Auftrag als verschickt markieren",
        setReceived: "Bestellung eingetroffen",
        minimumPrice: "Mindestbestellwert",
        partsPrice: "Produktionskosten",
        transportPrice: "Lieferkosten",
        discount: "Rabatt",
        totalPrice: "Gesamt",
        contractorIssueResolveNote: "Anmerkung an Dienstleister",
        cancel: "Storno",
        externalId: "Auftrags-Nr.",
        assignee: "Mitarbeiter",
        productionPortfolio: "Produktionsmappe",
      },
    },
    [resources.ARCHIVED_MODELS]: {
      name: "Modell |||| Modelle",
      fields: {
        id: "ID",
        rapidshopId: "Modell-Nr.",
        order: "Bestellung",
        shipment: "Lieferung",
        dateOrdered: "Bestelldatum",
        amount: "Anzahl",
        volume: "Volumen",
        height: "Höhe",
        width: "Breite",
        dimensions: "Maße",
        length: "Tiefe",
        surface: "Oberfläche",
        methodName: "Methode",
        modelFileUrl: "Download",
        modelPreviewUrl: "Vorschau",
        netPrice: "Preis",
        shopStatus: "Kunden Status",
        rohStatus: "Status",
        job: "Produktionsauftrag",
        uuid: "Referenz-Nr.",
        size: "Abmaße",
        assign: "Modell an Sub. zuweisen",
        unassign: "Sub. Zuweisung aufheben",
        material: "Material",
        sizeHWL: "Abmaße (Höhe x Breite x Tiefe)",
        markNeedsUpdate: "Nicht produzierbar",
        modelErrorLbl: "Die 3D-Daten wurden als fehlerhaft markiert! ",
        markAsFixed: "Als repariert markieren",
        editFiles: "Daten bearbeiten",
        download: "Datei Herunterladen",
        showJob: "zu Auftrag wechseln",
        addition: "Veredelung",
        notice: "Bauteilhinweis",
        singlePrice: "Einzelpreis",
        totalPrice: "Gesamtpreis",
        noteToContractor: "Notiz für Sub. Dienstleister",
        noteToContractorMultiModelWarning: "Die Notizen aller Modelle werden überschrieben!",
        noteToContractorNotSupported: "Notiz nicht unterstützt",
        noteToContractorCV: "Anmerkung", //On contractor view
        useNoticeAsNoteToContractor: "Kundennotiz übernehmen",
        addNoticeToNoteToContractor: "Kundennotiz anfügen",
        addCustomerNoteToNoteToContractor: "Bauteilhinweis anfügen",
        addAllNoticesToNoteToContractor: "Alle Kundennotizen anfügen",
        saveNoteToContractor: "Notiz speichern",
        prepaymentNotComplete: "Vorkasseauftrag ohne Zahlungseingang",
        paymentComplete: "Vollständig Bezahlt",
        paymentOk: "Zahlung nach Produktion",
        missingPriceEstimates: "Für dieses Modell existieren keine Angebote.",
        multipleNotesWarning: "Achtung: Es existieren verschiedene Kundennotizen für die ausgewählten Modelle.",
        qaApproved: "WAP Abgeschlossen",
        qaFailed: "WAP Fehler",
        additionDone: "Nachbearbeitung fertig",
      },
    },
    [resources.PRINT_JOBS]: {
      name: "Bauraum |||| Bauräume",
      fields: {
        id: "Bauraum #",
        printedAt: "Produktion",
        density: "Packdichte (%)",
        height: "Bauraumhöhe (mm)",
        printDuration: "Druckdauer (Stunden)",
        coolingDuration: "Abkühl-Dauer (Stunden)",
        completedAt: "Fertigstellung Druck",
        buildUnitCompletedAt: "Fertigstellung Bauraum",
        packedAt: "Packdatum",
        assignedTo: "Bearbeiter",
        printer: "Drucker",
        buildUnit: "BU",
        ncu: "NCU",
        notes: "Hinweistext",
        status: "Status",
        jobExternalId: "Sub.-Auftragsnummer",
        modelRapidshopId: "Modellnummer",
        modelPieces: "Stückzahl",
        modelNote: "Anmerkung",
        notesPlaceholder: "Notizen...",
        begin: "Beginn",
        printHeight: "Druckhöhe",
        name: "Bauräume",
        downloadButton: "Druckmappe",
        downloadedFileName: "Druckmappe%{id}.pdf",
        create: "Bauraum anlegen",
        enterJobIds: "Bitte Sub.Auftrag Nummer eingeben.",
      },
    },
    [resources.ARCHIVED_PRINT_JOBS]: {
      name: "Archiv. Bauraum |||| Archiv: Bauräume",
      fields: {
        id: "Bauraum #",
        printedAt: "Produktion",
        density: "Packdichte (%)",
        height: "Bauraumhöhe (mm)",
        printDuration: "Druckdauer (Stunden)",
        coolingDuration: "Abkühl-Dauer (Stunden)",
        completedAt: "Fertigstellung Druck",
        buildUnitCompletedAt: "Fertigstellung Bauraum",
        packedAt: "Packdatum",
        assignedTo: "Bearbeiter",
        printer: "Drucker",
        buildUnit: "BU",
        ncu: "NCU",
        notes: "Hinweistext",
        status: "Status",
        jobExternalId: "Sub.-Auftragsnummer",
        modelRapidshopId: "Modellnummer",
        modelPieces: "Stückzahl",
        modelNote: "Anmerkung",
        notesPlaceholder: "Notizen...",
        begin: "Beginn",
        printHeight: "Druckhöhe",
        name: "Bauräume",
        downloadButton: "Druckmappe",
        downloadedFileName: "Druckmappe%{id}.pdf",
        create: "Bauraum anlegen",
        enterJobIds: "Bitte Sub.Auftrag Nummer eingeben.",
      },
    },
    [resources.ARCHIVED_ORDERS]: {
      name: "Bestellung |||| Bestellungen",
      fields: {
        id: "ID",
        rapidshopId: "Bestellnr.",
        active: "Aktiv",
        customer: "Kunde",
        paymentAddress: "Rechnungsadresse",
        dateCreated: "Upload-Datum",
        dateUpdated: "Zuletzt geändert",
        dateOrdered: "Bestelldatum",
        models: "Modelle",
        materials: "Materialien",
        shipments: "Sendungen",
        contactorRapidshopId: "Interne Bestell-Nr.",
        auditLog: "Audit-Log",
      },
    },
    [resources.PRINT_PREPARATIONS]: {
      name: "Druckvorbereitung",
    },
    [resources.POSTPROCESSING_JOB]: {
      name: "Nachbearbeitungsauftrag |||| Nachbearbeitungsaufträge",
      fields: {
        addition: "Veredelung",
        execution: "Ausführung",
        start: "Anfang",
        handover: "Übergabe",
        end: "Fertigstellung Druck",
        modelDescription: "Modellbeschreibung",
        modelDescriptionFileName: "Modellbeschreibung%{id}.pdf",
        externalAdditionOrder: "Begleitbrief",
        externalAdditionOrderFileName: "Begleitbrief%{id}.pdf",
      },
    },
    [resources.POSTPROCESSING_JOB_LIST]: {
      name: "Nachbearbeitungsauftrag |||| Nachbearb. Aufträge",
      fields: {
        handoverEdit: "Datum Übergabe extern",
        endEdit: "Datum Fertig",
      },
    },
    debug: {
      name: "System Daten",
    },
  },
  printJobUploadLabel: "Netfabb CSV",
  printJobUploadResultEmpty: "Keine Übereinstimmungen gefunden",
  jobsNumberLabel: "Sub. Auftragsnummer",
  keepOrientation: "Orientierung beibehalten",
  dontKeepOrientation: "Orientierung nicht beibehalten",
  allowHollow: "Aushöhlen erlauben",
  doNotHollow: "Nicht aushöhlen",
  showOtherMaterialiseEstimates: "Alle Materialise Preise Anzeigen",
  wallthicknessSuite: "Wandstärke Suite",
  browserNotSupported: "Browser wird nicht unterstützt",
  browserNotRecommended: "Browser wird nicht empfohlen",
  browserNotSupportedBox:
    'Ihr aktueller Browser wird möglicherweise nicht komplett unterstützt. Bitte verwenden Sie "Google Chrome".',
  browserNotRecommendedBox: 'Ihr aktueller Browser wird nicht empfohlen. Bitte verwenden Sie "Google Chrome".',
  restartScheduled: "Server-Neustart %{restartingIn}",
  restartScheduledAt:
    "Achtung: Server-Neustart geplant für %{time}. Bitte schließen Sie bis dahin Ihre aktuelle Sitzung ab.",
  pingError: "Server nicht erreichbar",
  stsPingError: "Datei-Server nicht erreichbar",
  pingErrorDetails:
    "Der Server ist nicht mehr erreichbar. Bitte unterbrechen Sie Ihre Arbeit, bis die Verbindung wiederhergestellt wurde.",
  stsPingErrorDetails:
    "Der Datei-Server ist nicht mehr erreichbar. Das Herunterladen und Bearbeiten von 3D-Modellen ist nicht verfügbar.",
  pingErrorLogin:
    "Der Server kann im Moment nicht erreicht werden. Bitte laden Sie die Seite später neu, um es erneut zu probieren.",
  analyseWallthickness: "Wandstärkeprüfung ausführen",
  wallthickness: "Wandstärke",
  criticalArea: "Kritischer Bereich",
  screens: {
    modelFileVersions: "Modell Datei-Versionen",
  },
  assignUser: "Ansprechpartner",
  assignUserSuccess: "Benutzer erfolgreich zugewiesen",
  modelFileNotAvailable: "Diese 3D-Datei ist leider nicht verfügbar.",
  noCompartmentAssigned: "Kein Fach",
  printJobTooLate:
    "Der zugewiesene Bauraum wird erst nach Lieferdatum der Bestellung %{order} abgeschlossen. |||| Der zugewiesene Bauraum wird erst nach Lieferdatum der Bestellungen %{order} abgeschlossen.",

  orderStatus: {
    ORDER_STATUS_UNASSIGNED: "Nicht zugewiesen",
    ORDER_STATUS_ASSIGNED: "Zugewiesen",
    ORDER_STATUS_NEW: "Neu",
    ORDER_STATUS_OFFER_SENT: "Anfrage gesendet",
    ORDER_STATUS_IN_PRODUCTION: "In Produktion",
    ORDER_STATUS_IN_TRANSPORT: "Anlieferung",
    ORDER_STATUS_RECEIVED: "Angeliefert",
    ORDER_STATUS_DECLINED: "Abgelehnt",
    ORDER_STATUS_NEEDS_UPDATE: "Änderung nötig",
    ORDER_STATUS_COMPLETED: "Versandfertig",
    ORDER_STATUS_CANCELED: "Storniert",
    ORDER_STATUS_ADDITIONAL_WORK: "Nachbearbeitung",
    ORDER_STATUS_QUALITY_CONTROL: "WAP Warenausgangsprüfung",
    ORDER_STATUS_QUALITY_CONTROL_FAILED: "WAP Fehler",
    ORDER_STATUS_UNKNOWN: "UNBEKANNT/FEHLER",
    contractorView: {
      ORDER_STATUS_UNASSIGNED: "Nicht zugewiesen",
      ORDER_STATUS_ASSIGNED: "Zugewiesen",
      ORDER_STATUS_NEW: "Neu",
      ORDER_STATUS_OFFER_SENT: "Anfrage erhalten",
      ORDER_STATUS_IN_PRODUCTION: "In Produktion",
      ORDER_STATUS_IN_TRANSPORT: "Anlieferung",
      ORDER_STATUS_RECEIVED: "Angeliefert",
      ORDER_STATUS_DECLINED: "Abgelehnt",
      ORDER_STATUS_NEEDS_UPDATE: "Änderung nötig",
      ORDER_STATUS_COMPLETED: "Versandfertig",
      ORDER_STATUS_CANCELED: "Storniert",
      ORDER_STATUS_ADDITIONAL_WORK: "Nachbearbeitung",
      ORDER_STATUS_QUALITY_CONTROL: "WAP Warenausgangsprüfung",
      ORDER_STATUS_QUALITY_CONTROL_FAILED: "WAP Fehler",
      ORDER_STATUS_UNKNOWN: "UNBEKANNT/FEHLER",
    },
  },
  transactionStatus: {
    ASSIGN: "ASSIGN",
    ORDER: "ORDER",
    DECLINE: "DECLINE",
    FIX_DATA: "FIX_DATA",
  },
  artJobStatus: {
    CREATED: "Nicht gestartet",
    PENDING: "In der Warteschlange",
    RUNNING: "Wird bearbeitet",
    PAUSED: "Pause",
    SUCCESSFUL: "Fertig",
    FAILED: "Fehler",
    SKIPPED: "Abgebrochen",
  },
  productionStatus: {
    PRODUCTION_STATUS_NEW: "Neu",
    PRODUCTION_STATUS_IN_PRODUCTION: "In Fertigung",
    PRODUCTION_STATUS_READY_FOR_SHIPPING: "Versandbereit",
    PRODUCTION_STATUS_SHIPPED: "Versendet",
  },
  detailedShopStatus: {
    CANCELLED: "Storniert",
    OFFER: "Angebot",
    AWAITING_PAYMENT: "Zahlung ausstehend",
    READY_FOR_PRODUCTION: "Produktionsbereit",
    COMPLETED: "Produziert",
    SHIPPED_TO_CUSTOMER: "Versendet",
  },
  autoAssignLogStatus: {
    SUCCESS: "Erfolgreich",
    FAILED_FORMULA_EVAL: "Fehler beim Ausführen der Formel",
    FAILED_NO_ESTIMATE: "Keine Zuweisung durch Formel",
    FAILED_NO_MODEL_ESTIMATES: "Kein Preis gefunden",
    FAILED_MAX_PRICE: "Preismaximum überschritten",
    FAILED_MAX_PRICE_DIFF: "Maximale Preisdifferenz überschritten",
    FAILED_WRONG_STATUS: "Modell wurde bereits zugewiesen",
    FAILED_TRANSITION_BLOCKED: "Aktion blockiert",
    FAILED_GENERIC: "Unbekannter Fehler",
  },
  autoAssignJobStatus: {
    success: "Automatische Zuweisung erfolgreich",
    error: "Automatische Zuweisung fehlgeschlagen",
  },
  checkAutoAssign: {
    title: "Formel prüfen",
    check: "Prüfen",
    results: "Ergebnis",
    noResults: "Keine Sub. Dienstleister gefunden",
  },
  role: {
    ROLE_SALES: "Vertrieb",
    ROLE_CONTRACTOR: "Dienstleister",
    ROLE_ADMIN: "Admin",
  },
  contractorType: {
    INTERNAL: "Intern",
    EXTERNAL: "Extern",
    DUMMY: "Dummy",
    MATERIALISE: "Materialise",
    MATERIALISE_FASTLANE: "Materialise Express",
  },
  formulaType: {
    MODEL_PRICE_FORMULA: "Modell-Preisformel",
    AUTO_ASSIGN_FORMULA: "Script für autom. Zuweisung",
  },
  templateType: {
    CONTRACTOR_OFFER: "Subdienstleister-Auftrag",
    CONTRACTOR_RESOLVED_PROBLEM: "Subdienstleister Fehler behoben",
    CONTRACTOR_JOB_CANCELED: "Subdienstleister-Auftrag storniert",

    PRODUCTION_PORTFOLIO: "Produktionsmappe",
    MODEL_DESCRIPTION: "Modellbeschreibung",
    EXTERNAL_ADDITION_ORDER: "Begleitbrief",

    JOB_ACCEPTED_NOTIFICATION: "Benachrichtigung: Sub. Auftrag akzeptiert",
    JOB_DECLINED_NOTIFICATION: "Benachrichtigung: Sub. Auftrag fehlerhaft",
    JOB_IN_DELIVERY_NOTIFICATION: "Benachrichtigung: Sub. Auftrag wird angeliefert",
    JOB_DOCUMENTS_ADDED_NOTIFICATION: "Benachrichtigung: Dokument von Dienstleister hinzugefügt",
    JOB_COMMENTED_NOTIFICATION: "Benachrichtigung: Sub. Auftrag kommentiert",
    SHIPMENT_COMMENTED_NOTIFICATION: "Benachrichtigung: Bestellung kommentiert",
    MENTIONED_IN_COMMENT_NOTIFICATION: "Benachrichtigung: In Kommentar erwähnt",
    PREPAYMENT_PAYED_NOTIFICATION: "Benachrichtigung: Vorkasse bezahlt",
  },
  customerType: {
    COMMERCIAL: "Gewerblich",
    PRIVATE: "Privat",
  },
  filterEntity: {
    JOBS: "Sub. Aufträge",
    MODELS: "Modelle",
    ORDERS: "Bestellungen",
    CONTRACTORS: "Dienstleister",
    PRICE_ESTIMATES: "Angebote",
    MATERIALS: "Materialien",
    TEMPLATES: "Templates",
    SUPPORTED_MATERIALS: "Unt. Materialien",
    PRICE_FORMULAS: "Formeln",
    CUSTOMERS: "Kunden",
    SHIPMENTS: "Bestellungen",
    MODEL_STATUS_UPDATES: "Audits",
    SUBTASKS: "Unteraufgaben",
    ADDITION: "Veredelungen",
    DOCUMENT: "Dokumente",
    DOCUMENT_TYPE: "Dokument-Typen",
  },
  filterGraphType: {
    USER_CREATED: "Nutzer-erstellt",
  },
  printJobStatus: {
    NEW: "Offen",
    PACKED: "Gepackt",
    LOADED: "Geladen",
    PRINTING: "Im Druck",
    CANCELED: "Abbruch",
    COOLING: "Auskühlen",
    UNPACKED: "Entpackt",
    CLEANED: "Entstützt",
    SORTED: "Sortiert",
    DONE: "Abgeschlossen",
  },
  printersNames: {
    HPA: "HP-A",
    HPB: "HP-B",
    HPC: "HP-C",
    HPD: "HP-D",
    P110: "P110",
    OTHER: "Sonstiges",
  },
  buildUnit: {
    BU1: "BU 1",
    BU2: "BU 2",
    BU3: "BU 3",
    BU4: "BU 4",
    BU5: "BU 5",
    BU6: "BU 6",
    BU7: "BU 7",
    BU8: "BU 8",
    BU9: "BU 9",
    BU10: "BU 10",
    BU11: "BU 11",
    BU12: "BU 12",
    NCU1: "NCU 1",
    NCU2: "NCU 2",
    NCU3: "NCU 3",
    NCU4: "NCU 4",
    NCU5: "NCU 5",
    NCU6: "NCU 6",
  },
  modelPartState: {
    dialogTitle: "Status der Teilmengen",
    unassignedCount: "UNZUGEORDNET", //According to ROD-241 it is temporarily out
    assignedCount: "ZUGEWIESEN",
    packedCount: "GEPACKT",
    printingCount: "DRUCKEN",
    printedCount: "GEDRUCKT",
    receivedCount: "ANGELIEFERT/SORTIERT",
    postProcessingCount: "NACHBEARBEITET",
    qaCount: "WAP",
    readyForShippingCount: "VERSANDFERTIG",
    shippedCount: "VERSANDT",
    defectCount: "DEFEKT",
  },
  customerShowCategory: {
    person: "Persönliche Angaben",
    contact: "Kontaktinformation",
    address: "Standort",
  },
  upload: {
    uploading: "Läd hoch...",
    uploadingWithProgress: "Läd hoch (%{progress}%)...",
    cancel: "Abbrechen",
    start: "Hochladen",
    startWithFilename: "%{filename} Hochladen",
    startWithFilecount: "%{filecount} Dateien Hochladen",
  },
  sendOfferDialog: {
    title: "Bestellen / Auftrag absenden",
    body1: "Bitte bestätigen Sie das Absenden des Produktionsauftrags.",
  },
  restartDialog: {
    title: "Server Neustart bestätigen",
    body1:
      "Bitte bestätigen Sie den Neustart des Servers. Diese Aktion kann einige Minuten in Anspruch nehmen, währenddessen der Dienst nicht verwendet werden kann!",
  },
  reestimateDialog: {
    title: "Preise neu berechnen",
    body1:
      "Bitte bestätigen Sie die Neuberechnung der Preise. Diese Aktion kann einige Stunden in Anspruch nehmen, blockiert jedoch nicht die Nutzung der Anwendung.",
  },
  restartingAt: "Server-Neustart geplant für: %{time}",
  restart: "Server neustarten",
  declineReasons: {
    scale: "Falsche Skalierung",
    triangulation: "Schlechte Dreiecksqualität",
    fileError: "Fehler in der Datei",
    wallthickness: "Zu dünne Wandstärke",
    other: "Sonstiges",
  },
  declineModel: {
    markNeedsUpdate: "Nicht produzierbar",
    productionAction: "Als nicht produzierbar markieren",
    selectDeclineReason: "Grund auswählen",
    declineReason: "Anzupassen",
    declineDialogText: "Bitte bestätigen Sie, dass das gewähle Model nicht produziert werden kann.",
  },
  uploadDocument: {
    uploadDocument: "Dokument hochladen",
    selectDocumentType: "Bitte wählen Sie einen Dokumententyp aus.",
    selectFile: "Datei auswählen",
  },
  changePassword: {
    title: "Passwort ändern",
    button: "Passwort ändern",
    oldPassword: "Altes Passwort",
    newPassword: "Neues Passwort",
    newPasswordAgain: "Neues Passwort (Wdhl.)",
    change: "Passwort ändern & Ausloggen",
    notMatching: "Stimmt nicht überein",
    changeForUser: "Passwort für %{username} ändern",
  },
  downloadAll: "Alle herunterladen",
  changeCompartment: "Lagerplatz ändern",
  unsynchronized: "Synchronisation läuft / Keine Daten verfügbar",
  "Failed to fetch": "Server konnte nicht erreicht werden",
  syncModels: "Shop-Daten übertragen",
  reestimatePrices: "Preise neu berechnen",
  reestimationRunning: "Bitte haben Sie etwas Geduld während die Preise neu berechnet werden.",
  exportError: "Fehlerbericht erstellen",
  exportErrorSuccess: "Ein Fehlerbericht wurde erfolgreich erstellt und heruntergeladen.",
  materialiseReferenceError: "Die eingebebene ID ist ungültig.",
  materialiseModelUnsynched: "Materialise wird synchronisiert",
  action: "Aktion",
  invalidValue: "Fehlerhaft/ungültig",
  contractorUserWithWrongRolesWarning:
    'Ein Dienstleister-Benutzer sollte (ausschließlich) die Rolle "Dienstleister" besitzen!',
  contractorUserMissing: "Für diesen Dienstleister muss ein Benutzer angelegt werden, bevor er verwendet werden kann!",
  contractorIssueResolveNoteExternalView: "Hinterlegte Anmerkung:",
  applyAllNotices: "Alle Bauteilhinweise hinzufügen",

  autoOrderTime: {
    helperText:
      'Zeit in Minuten, bis ein Sub. Auftrag automatisch bestellt wird. "-1" heißt keine automatische Bestellung, wenn leer wird der globale Wert benutzt.',
    valueGlobal: "Für die automatische Bestellung wird die globale Konfiguration genutzt.",
    valueOff: "Die automatische Bestellung ist deaktiviert.",
    valueFixed: "Diesem Dienstelister zugewiesene Modelle werden nach %{smart_count}min automatisch bestellt.",
    listGlobal: "(Global)",
    listOff: "Aus",
    listFixed: "%{smart_count}",
  },

  notificationTypes: {
    JOB_ACCEPTED: "Sub. Auftrag akzeptiert",
    JOB_DECLINED: "Sub. Auftrag fehlerhaft",
    JOB_IN_DELIVERY: "Sub. Auftrag wird angeliefert",
    JOB_DOCUMENTS_ADDED: "Dokument von Dienstleister hinzugefügt",
    JOB_COMMENTED: "Sub. Auftrag kommentiert",
    SHIPMENT_COMMENTED: "Bestellung kommentiert",
    MENTIONED_IN_COMMENT: "In Kommentar erwähnt",
    PREPAYMENT_PAYED: "Vorkasse bezahlt",
  },

  notificationStyles: {
    LOWEST: "Niedrigste",
    INFO: "Info",
    UPDATE: "Update",
    WARNING: "Warnung",
    ALERT: "Alarm",
    CRITICAL: "Kritisch",
  },

  notifications: {
    JOB_ACCEPTED: "Sub. Auftrag akzeptiert",
    JOB_DECLINED: "Sub. Auftrag fehlerhaft",
    JOB_IN_DELIVERY: "Sub. Auftrag wird angeliefert",
    JOB_DOCUMENTS_ADDED: "Dokument von Dienstleister hinzugefügt",
    JOB_COMMENTED: "Sub. Auftrag kommentiert",
    SHIPMENT_COMMENTED: "Bestellung kommentiert",
    MENTIONED_IN_COMMENT: "In Kommentar erwähnt",
    PREPAYMENT_PAYED: "Vorkasse bezahlt",
  },

  bulkOps: {
    error: "Fehler",
    jobMenuItem: "Listenoperationen",
    actions: {
      title: "Aktionen",
      order: "Bestellen / Auftrag absenden",
      setInTransport: "Als verschickt markieren",
      setReceived: "Als eingetroffen markieren",
      setAccepted: "Bestellungen / Auftrag akzepieren",
      reset: "Zurücksetzen / Leeren",
    },
    alreadyExists: "Dieser Auftrag wurde bereits eingetragen",
    notFound: "Auftrag nicht gefunden",
    execute: "Aktion ausführen",
    response: "Ergebnis",
  },
  failureReasons: {
    local: {
      HP_A: "HP-A",
      HP_B: "HP-B",
      HP_C: "HP-C",
      HP_D: "HP-D",
      HP_E: "HP-E",
      HP_F: "HP-F",
      HP_G: "HP-G",
      SLS_A: "SLS-A",
      SLS_B: "SLS-B",
      SLMA: "SLMA",
      OTHER_PRINTER: "Anderer Drucker",
      INDEPENDENT_PRINTER: "Druckerunabhängig",
    },
    type: {
      ELEPHANT_SKIN: "Elephant Skin",
      THERMAL_BLEED: " Thermal Bleed",
      PRINTING_STRIPS: " Druckstreifen",
      BUBBLES: " Bubbles",
      DIMENSIONAL_DEVIATION: " Maßabweichung",
      DELAY: " Verzug",
      OTHERS_MACHINE_DEFECTS: " Sonstige Maschinenfehler",
      DURING_BLASTING_SUPPORTING: " Defekt beim Strahlen/Entstützen",
      OTHER_DEFECTS: " Sonstiger Defekt",
      LOST_UNKNOWN: " Verlust/unbekannt",
    },
  },

  errorCodes: {
    "0-00-404": "Nicht gefunden.",
    "1-00-404": "Bestellung nicht gefunden.",
    "2-00-404": "Modell nicht gefunden.",
    "3-00-404": "Dienstleister nicht gefunden.",
    "4-00-404": "Sub. Auftrag nicht gefunden.",
    "5-00-404": "Template nicht gefunden.",
    "6-00-404": "Kostenvoranschlag nicht gefunden.",
    "6-01-404": "Service nicht gefunden.",
    "7-00-404": "Preisformel nicht gefunden.",
    "8-00-404": "Ablehnungsgrund nicht gefunden.",
    "9-00-404": "Subtask nicht gefunden.",
    "10-00-404": "Nachbearbeitung nicht gefunden.",
    "11-00-404": "Adresse nicht gefunden.",
    "12-00-404": "Einstellung nicht gefunden.",
    "13-00-404": "Kunde nicht gefunden.",
    "14-00-404": "Material nicht gefunden.",
    "15-00-404": "Audit nicht gefunden.",
    "16-00-404": "Enität nicht gefunden.",
    "17-00-404": "Lieferung nicht gefunden.",
    "18-00-404": "Unt. Material nicht gefunden.",
    "19-00-404": "Benutzer nicht gefunden.",
    "20-00-404": "Lagerplatz nicht gefunden.",
    "21-00-404": "Rolle nicht gefunden.",
    "22-00-404": "Materialise Zuordnung nicht gefunden.",
    "23-00-404": "Materialise Fehler nicht gefunden.",
    "24-00-404": "Dokument-Typ nicht gefunden.",
    "25-00-404": "Dokument Fehler nicht gefunden.",
    "99-00-404": "Interner Fehler",

    "0-00-409": "Fehlerhafte Anfrage",
    "2-00-409": "Der Dienstleister ist mit diesem Model nicht kompatibel",
    "3-00-409": "Der Dienstleister unterstützt das gewählte Material nicht",
    "4-00-409": "Model nicht in Sub. Auftrag enthalten",
    "5-00-409": "Fehlerhafte Anfrage",
    "13-00-409": "Fehlerhafte Anfrage",
    "14-00-409": "Fehlerhafte Anfrage",
    "15-00-409": "Fehlerhafte Anfrage",
    "17-00-409": "Fehlerhafte Anfrage",
    "19-00-409": "Fehlerhafte Anfrage",

    "3-00-418": "Fehlerhafte Materialise Konfiguration",
    "4-00-418": "Fehlerhafte Materialise Konfiguration",
    "5-00-418": "Fehlerhafte Mail Template konfiguration",
    "5-01-418": "Mail-Template wird noch von in einer Dienstelister Konfiguration genutzt",
    "7-00-418": "Formel wird noch ein einer Konfiguration genutzt",
    "12-00-418": "Fehlerhafter Wert bzw. ungültiges Format",
    "15-00-418": "Anfrage nicht vollständig",
    "18-00-418": "Material und Dienstleister sind bereits einander zugewiesen",
    "19-00-418": "Ein Benutzer kann sich nicht selbst löschen",
    "19-01-418": "Benutzername bereits vergeben",
    "0-00-418": "Ungültige Aktion",
    "0-01-418": "Nicht gestattet / Keine Befugnis",
    "0-02-418": "Aktion abgelehnt: Kein Zugriff",
    "0-03-418": "Aktion abgelehnt: Kein Zugriff",
    "0-04-418": "Diese Aktion wird bereits bearbeitet.",
    "0-05-418": "Unbekannte Operation.",

    "0-00-419": "Ein unerwarteter Fehler ist aufgetreten. Bitte informieren Sie einen Entwickler.",
    "0-01-419": "Fehler beim erstellen des Anhangs der Mail.",
    "0-02-419": "Fehler beim erstellen der Produktionsmappe",
    "0-03-419": "Fehler beim Benachrichtigen des Dienstleisters: E-Mail konnte nicht gesendet werden",
    "0-04-419": "Fehler beim Hochladen der Daten",
    "0-08-419": "Die Datei konnte nicht gelesen werden oder ist fehlerhaft.",

    "6-00-419": "Für dieses Modell und Sub. existieren mehrere Kostenvoranschläge.",
    "6-01-419": "Für dieses Modell und Sub. wurde parallel bereits ein Kostenvoranschlag berechnet.",

    "2-00-420": "Ungültig",
    "2-01-420": "Ungültig",
    "3-00-420": "Ungültig",
    "4-00-420": "Ungültig",
    "5-00-420": "Ungültig",
    "7-00-420": "Ungültig",
    "12-00-420": "Ungültig",
    "15-00-420": "Ungültig",
    "16-00-420": "Ungültig",
    "17-00-420": "Ungültig",
    "18-00-420": "Ungültig",
    "19-00-420": "Ungültig",
    "19-01-420": "Ungültig",
    "19-02-420": "Falsches Passwort",
    "21-00-420": "Ungültig",
    "23-00-420": "Ungültig",
    "0-00-420": "Ungültig",
    "0-03-420": "Ungültig",
    "0-04-420": "Ungültig",
    "0-05-420": "Ungültig",
    "0-06-420": "Ungültig",
    "0-07-420": "Dateiname fehlt",
    "9-00-420": "Dieser Subtasktyp wird nicht unterstützt",
    "18-01-420": "Diese Kombination existiert bereits.",
    "25-01-420": "Upload mehrere Dokumente dieses Typs nicht möglich!",
    "25-02-420": "Dateiname/Format ungültig!",

    "0-00-421": "Aktion/Status ungültig",
    "2-00-421": "Aktion/Status ungültig",
    "4-00-421": "Aktion/Status ungültig",
    "4-01-421": "Auftragsbestätigung fehlt",

    "0-00-422": "Aktion blockiert: Es sind nicht alle Anforderungen für diese Aktion erfüllt",
    "2-00-422": "Aktion blockiert: Es sind nicht alle Anforderungen für diese Aktion erfüllt",
    "4-00-422": "Aktion blockiert: Es sind nicht alle Anforderungen für diese Aktion erfüllt",
    "4-01-422":
      "Aktion blockiert: Aufträge dürfen nur zugewiesen werden, wenn alle Modelle des Materials zugewiesen sind",
    "4-02-422": "Aktion blockiert: Es sind nicht alle Modelldateien mit Materialise synchronisiert",
    "2-01-422": "Aktion blockiert: Das Modell enthält nicht abgeschlossene Subtasks",
    "2-03-422": "Aktion blockiert: Die Modelldatei ist nicht mit Materialise synchronisiert",

    "5-00-423": "Ein Dienstleister kann nicht zwei Templates des gleichen Typs nutzen",
    "7-00-423": "Formel ungültig",
    "7-01-423": "Name bereits vergeben",
    "9-00-423": "Model Feld nicht unterstützt",
    "12-00-423": "Typ ungültig",
    "19-00-423": "Ein Nutzer für diesen Dienstleister existiert bereits",
    "19-01-423": "Der Nutzername muss mindestens 3 Zeichen lang sein",
    "19-02-423": "Das Passwort muss mindestens 3 Zeichen lang sein",
    "19-03-423": "Rolle/Rechte nicht erkannt",
    "19-04-423": "Dienstleister-Nutzer müssen einem Dienstleister zugewiesen sein",
    "20-00-423": "Status kann nicht entfernt werden, solange Modelle betroffen sind",

    "0-03-423": "Nicht gefunden",
    "0-04-423": "Feld-Kombination ungültig",
    "0-05-423": "Ungültig",
    "0-06-423": "Benötigt",
    "0-07-423": "Darf nicht geändert werden",
    "0-08-423": "Benötigt",
    "0-09-423": "Ungültig",
    "0-10-423": "Dateiname fehlt",

    "0-00-424": "Materialise API Fehler",
    "0-01-424": "Materialise API Fehler",
    "0-02-424": "Rechnungsadresse ungültig",
    "0-03-424": "Materialise API Fehler",
    "0-04-424": "Das Model wurde von Materialise gelöscht",
    "0-05-424": "Model nicht druckbar",
    "0-06-424": "Model Format nicht unterstützt",
    "0-07-424": "Model kann nicht verarbeitet werden",
    "0-10-424": "Preisberechnung fehlgeschlagen",
    "0-11-424": "Angebotserstellung fehlgeschlagen",
    "0-12-424": "Angebot wurde bereits bestellt",
    "0-13-424": "Model Dateiname zu lang",
    "0-14-424": "Währung nicht unterstützt",
    "0-15-424": "Veredelung nicht unterstützt",
    "0-16-424": "Model-Abmaße zu groß für Veredelung",
    "0-17-424": "Menge nicht erlaubt",
    "0-18-424": "Preis zu hoch für automatische Bestellung",
    "0-19-424": "Online-Bestellungen im Moment nicht möglich",
    "0-20-424": "Preis zu hoch für automatische Bestellung",
    "0-21-424": "Express mit Nicht-Express gemischt",
    "0-22-424": "Auftrag leer",
    "0-23-424": "Adresse fehlt",
    "0-24-424": "Rechnungszahlung nicht verfügbar",
    "0-25-424": "Referenznummer zu lang",
    "0-26-424": "Angebot nicht gefunden",
    "0-27-424": "Angebot wurde gelöscht",
    "0-28-424": "Model-Abmaße zu klein/groß",
    "0-29-424": "Model nicht gefunden",
    "0-30-424": "Verfahren nicht verfügbar",
    "0-31-424": "Material nicht verfügbar",
    "0-32-424": "Veredelung nicht verfügbar",
    "0-33-424": "Farbe nicht verfügbar",
    "0-34-424": "Kombination aus Verfahren/Material/Veredelung/Farbe nicht verfügbar",
    "0-35-424": "Angebot nicht gefunden",
    "0-36-424": "Orientierung beibehalten für diese Verfahren nicht unterstützt",
    "0-37-424": "Express für diese Verfahren nicht unterstützt",
    "0-38-424": "The next day technology cannot be mixed with other technologies",
    "0-39-424": "Angebot nicht gefunden",
    "0-40-424": "Lieferadresse nicht gfefunden",
    "0-41-424": "Rechnungsadresse nicht gefunden",
    "0-42-424": "Rechnungszahlung deaktiviert",
    "0-43-424": "Model-Datei größer als 100MB",
    "0-44-424": "Model Verarbeitung fehlgeschlagen",
    "0-45-424": "Preis kann nicht berechnet werden",
    "0-46-424": "Model-Datei Übertragung abgebrochen",
    "0-47-424": "Model-Datei Übertragung fehlgeschlagen",
    "0-48-424": "Die an Materialise übertragene Datei entspricht nicht der aktuellen Datei-Version",
  },

  filterMisc: {
    commitSearch: "Suchen",
    loading: "Lädt",
    pleaseWait: "Bitte warten",
    reset: "Filter zurücksetzen",
  },

  modelFailure: {
    shellCountFailure: "Die Datei enthält mehrere Einzelkörper.",
    fileFormatFailure: "Das Modell ist keine unterstützte 3D-Datei.",
    modelSizeTooLargeFailure: "Das Modell ist für das Verfahren zu groß.",
    modelSizeTooSmallFailure: "Das Modell is zu klein.",
    wallThicknessFailure: "Die Wandstärken des Modells sind für das Verfahren zu gering.",
    wallThicknessCheckTimeoutFailure: "Die Wandstärke konnte nicht automatisch ermittelt werden.",
  },

  ra: merge({}, englishMessages.ra, germanMessages.ra, customRaMessages),
};

console.log("V2", merge(customRaMessages, germanMessages.ra));
